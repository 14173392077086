import {Component, Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import {CONFIG_ENV} from '@/config';
import {ServiceUser} from '@/services/ServiceUser';
import user from '@/store/user';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";
import {TBalantaNav} from "@/types/TBalantaNav";
import {ServiceBalanta} from "@/services/ServiceBalanta";
import {getCurrentDateAsString, getLastYearFirstDayDateAsString} from "@/modules/utils";

@Options({
    name: "Balanta",
    components: {}
})
export default class Balanta extends Vue {
    public loadingInvoices=false;
    public balantaInNav:TBalantaNav = ServiceBalanta.balantaGoala;
    public existaBalanta=false;
    private downloadingFile = false;
    declare public $refs: any;
    public userStore = getModule(user);
    public pagination ={rowsPerPage: 0};
    private currentDateAsString=getCurrentDateAsString('dd.mm.yyyy');
    private lastYearFirstDayDateAsString=getLastYearFirstDayDateAsString('dd.mm.yyyy');
    public columns = [
        { name: 'DocumentDate', label: 'Data Document', field: 'DocumentDate', align: 'left', sortable: true },
        { name: 'DueDate', label: 'Data Scadenta', field: 'DueDate', align: 'left', sortable: true },
        { name: 'DocumentType', label: 'Tip Document', field: 'DocumentType', align: 'left' },
        { name: 'DocumentNo', label: 'Nr. Document', field: 'DocumentNo', align: 'left' },

        { name: 'CreditAmount',  label: 'Suma Credit', field: 'CreditAmount', align: 'right', sortable: true },
        { name: 'DebitAmount', label: 'Suma Debit', field: 'DebitAmount', align: 'right' },

        { name: 'CreditBalance', label: 'Sold Credit', field: 'CreditBalance', align: 'right', sortable: true },

        { name: 'DebitBalance', label: 'Sold Debit', field: 'DebitBalance', align: 'right' }
    ];
    public visibleColumns =[ 'DocumentType', 'DocumentNo', 'DocumentDate', 'DueDate', 'DebitAmount', 'CreditAmount','CreditBalance','DebitBalance'];
    get user(): TUser {
        return this.userStore.user;
    }

    public getBalantaFromNav(){
        const vueInst=this;
        vueInst.$q.loading.show();
        ServiceBalanta.getBalantaFromNav().then(response=>{
            vueInst.$q.loading.hide();
            vueInst.loadingInvoices=false;
            if(response.status=='success'){
                vueInst.balantaInNav=JSON.parse(JSON.stringify(response.balantaInNav));
                vueInst.existaBalanta=true;
            }

        })
    }

    public downloadPdfMobile(){
        ServiceBalanta.getBalancePdfMobile().then(response => {
            console.log(response);
            const fileName = 'balanta.pdf';
            const linkSource = 'data:application/pdf;base64,'+ response.message + '#title='+fileName;
            console.log(linkSource);
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        });
    }

    private downloadBalantaPdf(){
        const vueInst=this;
        if(vueInst.$q.platform.is.mobile) {
            vueInst.downloadPdfMobile();
        } else {
            const urlToGetFile=CONFIG_ENV.URL_BALANTA.getPdf;
            vueInst.downloadingFile=true;
            let urlBlobForPreviewFile: any = null;
            fetch(urlToGetFile, {
                credentials: 'include'
            })
                .then(resp => resp.blob())
                .then(blob => {
                    const link = document.createElement('a');
                    urlBlobForPreviewFile = window.URL.createObjectURL(blob);
                    link.href = urlBlobForPreviewFile;
                    link.target = '_self';
                    link.download = "balanta.pdf";
                    (document.body || document.documentElement).appendChild(link);
                    link.click();
                    setTimeout(()=>{
                        window.URL.revokeObjectURL(urlBlobForPreviewFile);
                        link.remove();
                        vueInst.downloadingFile=false;
                    }, 100);
                })
                .catch((err) => {
                    this.$q.notify({
                        color: 'red',
                        textColor: 'white',
                        type: 'negative',
                        message: 'Eroare! Fisierul nu poate fi descarcat!',
                        position: 'top',
                        timeout: 3500,
                    })
                });
        }
    }

    public activated(){
        this.loadingInvoices=true;
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar('BALANTA');
        }
        this.getBalantaFromNav();
    }

}
