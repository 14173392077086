import {Component, Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import {CONFIG_ENV} from '@/config';
import {ServiceUser} from '@/services/ServiceUser';
import user from '@/store/user';
import offers from '@/store/offers';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";
import {TOffer} from "@/types/TOffer";
import ContacteleMeleColorMetal from "@/components/ContacteleMeleColorMetal.vue";
import HierarchicalChainBrowseHeader from "@/components/HierarchicalChainBrowseHeader/HierarchicalChainBrowseHeader.vue";
import { ServiceInvoice } from '@/services/ServiceInvoice';


@Options({
    name: "Dashboard",
    components: {ContacteleMeleColorMetal, HierarchicalChainBrowseHeader}
})
export default class Dashboard extends Vue {
    public inputSearchArticle='';
    declare public $refs: any;
    public userStore = getModule(user);
    public offersStore = getModule(offers);
    public overdueInvoiceCount = 0;
    public showAlertDepasireTermenFacturi=false;
    public showAlertCount=false;

    get user(): TUser {
        return this.userStore.user;
    }

    get offers():TOffer[]{
        return this.offersStore.offers;
    }
    public closeAlertDepasireTermenFacturi(){
        this.showAlertDepasireTermenFacturi=false;
    }

    public onGoArticoleFavorite(){
        this.$router.push({name: 'ArticoleFavorite'});
    }

    public onGoCautaArticole(){
        this.$router.push({name: 'BrowseCategories',  params: { pid: '0' }});
    }

    public onGoToOffers(){
        this.$router.push({name: 'Offers'});
    }

    public onGoNomAdreseLivrare(){
        this.$router.push({name: 'AdreseLivrare'});
    }

    public onGoFacturi(){
        this.$router.push({name: 'Facturi'});
    }

    public onGoBalanta(){
        this.$router.push({name: 'Balanta'});
    }

    public onGoDateCompanie(){
        this.$router.push({name: 'DateCompanie'});
    }

    public openTehnipedia(){
        window.open("https://color-metal.ro/ro/tehnipedia");
    }

    public openLink(link: string){
        window.open(link);
    }

    public onGoAlerte(){
        this.$router.push({name: 'Alerte'});
        this.overdueInvoiceCount = 0;
        this.showAlertCount = false;
    }

    public onGoUsersCompany(){
        this.$router.push({name: 'UsersCompany'});
    }

    public onGoDocumenteCompany(){
        this.$router.push({name: 'Documente'});
    }

    public activated(){
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showmenubar(true);
            this.userStore.set_page_transition('fade-in-left');
        }else{
            this.userStore.set_page_transition('fade-in-left');
        }
    }

    public created() {
        ServiceInvoice.getInvoicesFromNAV().then(response=>{ 
            if(response.status=='success'){
                this.showAlertDepasireTermenFacturi = response.areInvoicesOverdue;
                this.overdueInvoiceCount = response.overdueInvoiceCount;
                this.showAlertCount = response.areInvoicesOverdue;
            } 
        });
    }

}
